import { Component, OnInit, TemplateRef } from '@angular/core';

import { Cash } from '../../_models/cash';
import { Unit, U } from '../../_models/unit';
import { Store } from '../../_models/store';
import { Supplier } from '../../_models/supplier';
import { Product } from '../../_models/product';

import { OPS } from '../../_models/ops';

import { ConService } from '../../_services/con.service';
import { AlertifyService } from '../../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';


@Component({
  selector: 'app-credit',
  templateUrl: './credit.component.html',
  styleUrls: ['./credit.component.css']
})
export class BCreditComponent implements OnInit {

  title: string = "Buy Credit Operation"

exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
}
  single: OPS;
  modalRef: BsModalRef;


    ops: OPS;
    opss: OPS[];

    cash: Cash;
    cashs: Cash[];

    unit: U;
    units: U[];

    store: Store;
    stores: Store[];

    supplier: Supplier;
    suppliers: Supplier[];

    product: Product;
    products: Product[];


    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

     add: OPS;

     formtitle: string = "New Operation"

    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }
    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }




    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getBuyCredit(id).subscribe((res: OPS) => {
       this.single = res;

      })}






    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.ops = { _id: "", product: "",  store: "", supplier: "", cash: "",  unit: "", quantity: null, price: null };

    }

    refreshData() {
      this.con.getBuyCredits().subscribe((res) => { this.opss = res as OPS[]; });
      this.con.countBuyCredit().subscribe((res) => { this.count = res; });

      this.con.getCashes().subscribe((res) => { this.cashs = res as Cash[]; });
      this.con.getSuppliers().subscribe((res) => { this.suppliers = res as Supplier[]; });
      this.con.getStores().subscribe((res) => { this.stores = res as Store[]; });
      this.con.getProducts().subscribe((res) => { this.products = res as Product[]; });


    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Bank------------------------- */


          this.con.addBuyCredit(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Bank------------------------- */

        this.con.putBuyCredit( SForm.value ).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Operation"

        });
      }
    }



    delete(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delBuyCredit(_id).subscribe((res) => {
        this.refreshData();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(ops: OPS) {
      this.con.ops = ops;
      this.con.getProduct(this.con.ops.product).pipe(map(data => data['unit'])).subscribe((res) => { this.unit = res as U; } );
      this.con.ops = ops;
    }



    gUnit(id){
      this.con.getProduct(id).pipe(map(data => data['unit'])).subscribe((res) => { this.unit = res as U; } );
    }


    export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}

    changetitle(){this.formtitle = "Edit Operation"}

  }

