import { Component, OnInit, TemplateRef } from '@angular/core';

import { Cash } from '../../_models/cash';
import { Unit, U } from '../../_models/unit';
import { Store } from '../../_models/store';
import { Supplier } from '../../_models/supplier';
import { Product } from '../../_models/product';

import { OPS } from '../../_models/ops';

import { ConService } from '../../_services/con.service';
import { AlertifyService } from '../../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';


@Component({
  selector: 'app-cash1st-balance',
  templateUrl: './cash1st-balance.component.html',
  styleUrls: ['./cash1st-balance.component.css']
})
export class Cash1stBalanceComponent implements OnInit {
  title: string = "Buy Operation By Cash"
  exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
  }

  single: OPS;
  modalRef: BsModalRef;


    ops: OPS;
    opss: OPS[];

    cash: Cash;
    cashs: Cash[];



    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

     add: OPS;

     formtitle: string = "New Operation"

     checkedNot: boolean = true;
     isDisabled: boolean = false;
    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }
    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }




    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getOpeningCash(id).subscribe((res: OPS) => {
       this.single = res;

      })}






    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.ops = { _id: "", cash: "",  total: null };

    }

    refreshData() {
      this.con.getCashes().subscribe((res) => { this.cashs = res as Cash[]; });
      this.con.getOpeningCashs().subscribe((res) => { this.opss = res as OPS[]; console.log(res); });
      this.con.countOpeningCash().subscribe((res) => { this.count = res; });
    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Cash------------------------- */


          this.con.addOpeningCash(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Cash------------------------- */
console.log(SForm.value);
        this.con.putOpeningCash( SForm.value ).subscribe((res) => {


          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Operation"
          this.isDisabled = false;
        });
      }
    }



    delete(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delOpeningCash(_id).subscribe((res) => {
        this.isDisabled = false;
        this.refreshData();
        this.resetForm();
        this.alertify.success('Item Deleted Successfully');

      }); } );
    }


    onEdit(ops: OPS) {
      this.con.ops = ops;
      if(this.con.ops.accounting[0].credit > 0){ this.checkedNot = true;} else{ this.checkedNot = false;}

      this.isDisabled = true;

    }

    export(){this.exportAsService.save(this.exportAsConfig, 'exported-buy-cash').subscribe(() => {}); }

    changetitle(){this.formtitle = "Edit Operation"}


  }

