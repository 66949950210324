
import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';


import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { UserX } from '../_models/userx';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  xname: string;
  xemail: string;

  name: string;
  email: string;

  currentPassword: string;
  newPassword: string;


  term: any;
  p: number = 1;
  x: number = this.con.limit;


  constructor( public auth: AuthService, public con: ConService, private alertify: AlertifyService) { }

  ngOnInit() {
    this.refreshdata();
    this.resetForm();
    this.resetForm2();
  }


  refreshdata(){
    this.auth.getMe().pipe(map(data => data['name'])).subscribe((res) => { this.xname = res;});
    this.auth.getMe().pipe(map(data => data['email'])).subscribe((res) => { this.xemail = res;});
  }


  resetForm(myForm?: NgForm) {
    if (myForm) { myForm.reset(); }
    this.name = "";
    this.email = "";
  }

  resetForm2(Form2?: NgForm) {
    if (Form2) { Form2.reset(); }
    this.currentPassword = "";
    this.newPassword = "";
  }

  onSubmit(myForm: NgForm) {

      this.auth.editMe( myForm.value ).subscribe((res) => {

        this.resetForm();
        this.alertify.success('Your Profile updated Successfully');
        this.refreshdata();

      });
    }



    onSubmit2(Form2: NgForm) {

      this.auth.keyMe( Form2.value ).subscribe((res) => {
        this.resetForm2();
        this.alertify.success('Your password changed Successfully');


      });
    }
  }








