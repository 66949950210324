import { Component, OnInit, TemplateRef } from '@angular/core';

import { Product } from '../_models/product';
import { Unit, U } from '../_models/unit';
import { Supplier } from '../_models/supplier';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css'],
  providers: [ConService]
})
export class ProductsComponent implements OnInit {

  title: string = "Products"

  exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'table',
      options: {  orientation: 'landscape',  margins: { top: '20' } },
      download: true,
      fileName: "exportedFile"
  }
  single: Product;
  modalRef: BsModalRef;

  product: Product;
  products: Product[];

  unit: U;
  units: U[];
  suppliers: Supplier[];

  count: any;
  term: any;
  p: number = 1;
  x: number = this.con.limit;

  formtitle: string = "New Product"


  constructor(public con: ConService,
              private alertify: AlertifyService,
              private modalService: BsModalService,
              private exportAsService: ExportAsService) { }

  ngOnInit() {
    this.refreshData();
    this.resetForm();
  }


  openModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(template);
    this.con.getProduct(id).subscribe((res: Product) => {

     this.single = res;


    })}


  resetForm(myForm?: NgForm) {
    if (myForm) { myForm.reset(); }
    this.product = { _id: "", name: "",  unit: {} ,createdAt: null, updatedAt: null};

  }

  refreshData() {
    this.con.getProducts().subscribe((res) => { this.products = res as Product[]; });
    this.con.countProducts().subscribe((res) => { this.count = res; });

    this.con.getUnits().pipe(map(data => data['data'])).subscribe((res) => { this.units = res as U[]; });
    this.con.getSuppliers().subscribe((res) => { this.suppliers = res as Supplier[]; });
  }


  onSubmit(myForm: NgForm) {
    if (myForm.value._id == "") {
  // /* --------------------add  Product------------------------- */
  //       myform.value  = this.con.product

        this.con.addProduct(myForm.value).subscribe((res) => {
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item Saved Successfully');

      });
    }
    else {
  /* --------------------update  Product------------------------- */
      this.con.putProduct( myForm.value ).subscribe((res) => {
        console.log(myForm.value);
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item updated Successfully');
        this.formtitle = "New Product";
      });
    }
  }



  deleteProduct(_id: string) {

    this.alertify.alert('Confirm Message', ()=> { this.con.delProduct(_id).subscribe((res) => {
      this.refreshData();
      this.alertify.success('Item Deleted Successfully');
    }); } );
  }


  onEdit(product: Product) {
    this.product = product;
  }


  export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}

  changetitle(){this.formtitle = "Edit Operation";}

}
