import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { map } from 'rxjs/operators';
import { ConService } from '../_services/con.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  userNow: string;
  units: string;
  products: string;
  customers: string;
  suppliers: string;

  constructor(public auth: AuthService, private con: ConService) { }

  ngOnInit() {
 this.userNow = this.auth.name;
 this.con.getUnits().pipe(map(data => data['count'])).subscribe((res) => { this.units = res;  });
 this.con.countProducts().subscribe((res) => { this.products = res;  });
 this.con.countCustomers().subscribe((res) => { this.customers = res;  });
 this.con.countSuppliers().subscribe((res) => { this.suppliers = res;  });
  }
}
