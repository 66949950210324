import { Component, OnInit } from '@angular/core';
import { User } from '../_models/user';


import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  title: string = "Users"

  exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'table',
      options: {  orientation: 'landscape',  margins: { top: '20' } },
      download: true,
      fileName: "exportedFile"
  }
  user: User;
  users: User[];

  pass: boolean = true;




  Ucount: any;
  Acount: any;

  term: any;
  p: number = 1;
  x: number = this.con.limit;

  formtitle: string = "New User"

  constructor( public con: ConService, private alertify: AlertifyService, private exportAsService: ExportAsService) { }

  ngOnInit() {
    this.refreshData();
    this.resetForm();
  }

  refreshData() {
    this.con.getUsers().subscribe((res) => { this.users = res as User[]; });
    this.con.countUsers().subscribe((res) => {  this.Ucount = res; });
    this.con.countAdmins().subscribe((res) => {  this.Acount = res; });

  }



  resetForm(myForm?: NgForm) {
    if (myForm) { myForm.reset(); }
    this.con.user = { _id: "", name: "",  email: "", password: ""  ,createdAt: null, updatedAt: null};
  }

  onSubmit(myForm: NgForm) {
    if (myForm.value._id == "") {
  // /* --------------------add  Product------------------------- */
  //       myform.value  = this.con.product

        this.con.addUser(myForm.value).subscribe((res) => {
        console.log( res );
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item Saved Successfully');

      });
    }
    else {
  /* --------------------update  Product------------------------- */
      this.con.putUser( myForm.value ).subscribe((res) => {
        console.log(myForm.value);
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item updated Successfully');
        this.pass = true;
        this.formtitle = "New User";
      });
    }
  }



  deleteUser(_id: string) {
    this.alertify.alert('Confirm Message', ()=> { this.con.delUser(_id).subscribe((res) => {
      this.refreshData();
      this.alertify.success('Item Deleted Successfully');
    }); } );
  }


  onEdit(user: User) {
    this.con.user = user;
    this.pass = false;
  }
  export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}
  changetitle(){this.formtitle = "Edit User";}
}

