import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { map } from 'rxjs/operators';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
email: string;
password: string;
  loginUserData = {
    email: '',
    password: ''
  };
  constructor(private auth:AuthService , private router : Router, private alertify: AlertifyService) { }
  ngOnInit() {}


  doLogin(){
    this.auth.login(this.loginUserData).pipe(map(data => data['token'])).subscribe(
      res => {
        localStorage.setItem('token', res);
        this.router.navigate(['home']);
        this.alertify.success(`login successfully, Welcome ${this.auth.userNow}`);

 /*        this.auth.getMe().pipe(map(data => data['name'])).subscribe((res) => { this.auth.name = res;});
        this.auth.getMe().pipe(map(data => data['email'])).subscribe((res) => { this.auth.email = res;});
        this.auth.getMe().pipe(map(data => data['role'])).subscribe((res) => { this.auth.role = res;}); */

        },
      error=>{
        this.alertify.error('bad login info');
      }
    )
  }

}
