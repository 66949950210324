

import { Component, OnInit, TemplateRef } from '@angular/core';


import { Unit, U } from '../../_models/unit';
import { Res } from '../../_models/res';
import { Store } from '../../_models/store';
import { Product } from '../../_models/product';

import { OPS } from '../../_models/ops';

import { ConService } from '../../_services/con.service';
import { AlertifyService } from '../../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';



@Component({
  selector: 'app-store1st-balance',
  templateUrl: './store1st-balance.component.html',
  styleUrls: ['./store1st-balance.component.css']
})
export class Store1stBalanceComponent implements OnInit {

  title: string = "Buy Operation By Store"
  exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
  }

  single: OPS;
  modalRef: BsModalRef;



  unit: U;
  units: U[];


    ops: OPS;
    opss: OPS[];

    store: Store;
    stores: Store[];

    product: Product;
    products: Product[];



    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

     add: OPS;

     formtitle: string = "New Operation"

     checkedNot: boolean = true;
     isDisabled: boolean = false;
    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }
    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }




    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getOpeningStore(id).subscribe((res: OPS) => { this.single = res; });
    }






    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.ops = { _id: "", store: "", "product": "" , "unit": "" , quantity: null };

    }

    refreshData() {
      this.con.getStores().subscribe((res) => { this.stores = res as Store[]; });
      this.con.getProducts().subscribe((res) => { this.products = res as Product[]; });
      this.con.getOpeningStores().subscribe((res) => { this.opss = res as OPS[]; });
      this.con.countOpeningStore().subscribe((res) => { this.count = res; });
    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Store------------------------- */


          this.con.addOpeningStore(SForm.value).subscribe((res: Res) => {
          this.resetForm();
          this.refreshData();
        });
      }
      else {

    /* --------------------update  Store------------------------- */
console.log(SForm.value);
        this.con.putOpeningStore( SForm.value ).subscribe((res) => {


          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Operation"
          this.isDisabled = false;
        });
      }
    }



    delete(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delOpeningStore(_id).subscribe((res) => {
        this.refreshData();
        this.resetForm();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(ops: OPS) {
      this.resetForm();
      this.con.ops = ops;
      this.isDisabled = true;

      this.con.getProduct(this.con.ops.product).pipe(map(data => data['unit'])).subscribe((res) => { this.unit = res as U; } );
      this.con.ops = ops;
    }


    gUnit(id){  this.con.getProduct(id).pipe(map(data => data['unit'])).subscribe((res) => { this.unit = res as U; } );  }

    export(){this.exportAsService.save(this.exportAsConfig, 'exported-buy-supplier').subscribe(() => {}); }

    changetitle(){this.formtitle = "Edit Operation"}


  }

