import { Component, OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { map, tap, shareReplay, retryWhen, delayWhen } from 'rxjs/operators';
import { UserX } from 'src/app/_models/userx';
import { Observable, timer } from 'rxjs';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  //  user$ : Observable<UserX>;
  //  NN$: Observable<string>;
  //  EE$: Observable<string>;
  //  RR$: Observable<string>;

   now: number;
  // userNow: string;
  // userRole: string;

  name: string;
  email: string;
  role: string;





    constructor(private alertify: AlertifyService, public auth: AuthService, private router: Router) { setInterval(() => { this.now = Date.now(); }, 1); }


    ngOnInit() {

/*      const user$ = this.auth.getMe()
     .pipe(
         tap(() => console.log("HTTP request executed")),
         shareReplay(),
         retryWhen(errors =>
             errors.pipe(   delayWhen(() => timer(2000)   )
         ) )
     );





      this.NN$ = user$.pipe(map(data => data['name']));
      this.EE$ = user$.pipe(map(data => data['email']));
      this.RR$ = user$.pipe(map(data => data['role'])); */





       this.init();


     }





     init(){
       this.auth.getMe().pipe(map(data => data['name'])).subscribe((res) => {this.name = res; this.auth.userNow = res;});
       this.auth.getMe().pipe(map(data => data['email'])).subscribe((res) => { this.email = res; this.auth.userEmail = res});
       this.auth.getMe().pipe(map(data => data['role'])).subscribe((res) => { this.role = res; this.auth.role = res;});
    }



     out(){  this.auth.logout().subscribe( next => this.alertify.success('logout successfully')  );
    }


  /*   here(){ return this.auth.loggedIn(); } */

    admin(){ return this.auth.role == 'admin'; }

}
