import { Component, OnInit, TemplateRef } from '@angular/core';

import { Store } from '../_models/store';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {

  title: string = "Stores"

  exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'table',
      options: {  orientation: 'landscape',  margins: { top: '20' } },
      download: true,
      fileName: "exportedFile"
  }

  single: Store;
  modalRef: BsModalRef;

    store: Store;
    stores: Store[];


    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;


    formtitle: string = "New Store"


    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }

    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }



    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getStore(id).subscribe((res: Store) => {

       this.single = res;


      })}



    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.store = { _id: "", name: "" ,createdAt: null, updatedAt: null};

    }

    refreshData() {
      this.con.getStores().subscribe((res) => { this.stores = res as Store[]; });
      this.con.countStores().subscribe((res) => { this.count = res; });

    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Store------------------------- */
          this.con.addStore(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Store------------------------- */
        this.con.putStore( SForm.value ).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Store"
        });
      }
    }



    deleteStore(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delStore(_id).subscribe((res) => {
        this.refreshData();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(store: Store) {
      this.con.store = store;
    }

    export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}
    changetitle(){this.formtitle = "Edit Store"}
  }

