import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './auth/nav/nav.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';

import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from '@angular/forms'
import { AuthService } from './_services/auth.service';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { AuthGuard } from './_guards/auth.guard';
import { UnitsComponent } from './units/units.component';
import { ProductsComponent } from './products/products.component';
import { JoinComponent } from './join/join.component';
import { ConService } from './_services/con.service';


import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {TimeAgoPipe} from 'time-ago-pipe';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { CustomersComponent } from './customers/customers.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { ProfileComponent } from './profile/profile.component';
import { StoresComponent } from './stores/stores.component';
import { CashBoxesComponent } from './cash-boxes/cash-boxes.component';
import { BanksComponent } from './banks/banks.component';


import { ModalModule } from 'ngx-bootstrap/modal';

import { BCashComponent } from './buy/cash/cash.component';
import { BBankComponent } from './buy/bank/bank.component';
import { BCreditComponent } from './buy/credit/credit.component';

import { SCashComponent } from './sell/cash/cash.component';
import { SBankComponent } from './sell/bank/bank.component';
import { SCreditComponent } from './sell/credit/credit.component';
import { Cash1stBalanceComponent } from './1st_balance/cash1st-balance/cash1st-balance.component';
import { Bank1stBalanceComponent } from './1st_balance/bank1st-balance/bank1st-balance.component';
import { Customer1stBalanceComponent } from './1st_balance/customer1st-balance/customer1st-balance.component';
import { Supplier1stBalanceComponent } from './1st_balance/supplier1st-balance/supplier1st-balance.component';
import { Store1stBalanceComponent } from './1st_balance/store1st-balance/store1st-balance.component';
import { CustomersPaymentComponent } from './payment/customers-payment/customers-payment.component';
import { SuppliersPaymentComponent } from './payment/suppliers-payment/suppliers-payment.component';
import { ExportAsModule } from 'ngx-export-as';
import { Nav2Component } from './nav2/nav2.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    LoginComponent,
    HomeComponent,
    UnitsComponent,
    ProductsComponent,
    JoinComponent,
    TimeAgoPipe,
    DashboardComponent,
    UsersComponent,
    CustomersComponent,
    SuppliersComponent,
    ProfileComponent,
    StoresComponent,
    CashBoxesComponent,
    BanksComponent,

    BCashComponent,
    BBankComponent,
    BCreditComponent,

    SCashComponent,
    SBankComponent,
    SCreditComponent,
    Cash1stBalanceComponent,
    Bank1stBalanceComponent,
    Customer1stBalanceComponent,
    Supplier1stBalanceComponent,
    Store1stBalanceComponent,
    CustomersPaymentComponent,
    SuppliersPaymentComponent,
    Nav2Component,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,

    ModalModule.forRoot(),

    ExportAsModule

  ],
  providers: [
    AuthService,
    ErrorInterceptorProvider,
    AlertifyService,
    AuthGuard,
    ConService ],

  bootstrap: [AppComponent]
})
export class AppModule { }
