import { Component, OnInit, TemplateRef } from '@angular/core';
import { U, Unit } from '../_models/unit';
import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {  BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-units',
  templateUrl: './units.component.html',
  styleUrls: ['./units.component.css'],
  providers: [ConService]
})
export class UnitsComponent implements OnInit {

  title: string = "Units"

  exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'table',
      options: {  orientation: 'landscape',  margins: { top: '20' } },
      download: true,
      fileName: "exportedFile"
  }

  unit: U;
  units: Unit[];
  res: any;
  count: any;
  term: any;
  p: number = 1;
  x: number = this.con.limit;

  single: U;
  modalRef: BsModalRef;

  formtitle: string = "New Unit"

  constructor(public con: ConService,
              private alertify: AlertifyService,
              private modalService: BsModalService,
              private exportAsService: ExportAsService) { }

  ngOnInit() {
    this.refreshData();
    this.resetForm();
    // this.initLimit();
  }





  openModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(template);
    this.con.getUnit(id).pipe(map(data => data['data'])).subscribe((res: U) => {

     this.single = res;


    })


  }


  // initLimit(){
  //   this.x = 5;
  //   this.term = "";
  // }

  resetForm(myForm?: NgForm) {
    if (myForm) { myForm.reset(); }
     this.unit = { _id: "", name: "", large: "", small: "", ratio: null, createdAt: null, updatedAt: null};

  }

  refreshData() {
    this.con.getUnits().pipe(map(data => data['data'])).subscribe((res) => { this.units = res;  });
    this.con.getUnits().pipe(map(data => data['count'])).subscribe((res) => { this.count = res;  });

/*     this.con.countUnits().subscribe((res) => {
      this.count = res;
    }); */
  }


  onSubmit(myForm: NgForm) {
    if (myForm.value._id == "") {
  // /* --------------------add  Unit------------------------- */
  //       myform.value  = this.con.unit

        this.con.addUnit(myForm.value).subscribe((res) => {
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item Saved Successfully');

      });
    }
    else {
  /* --------------------update  Unit------------------------- */
      this.con.putUnit( myForm.value ).subscribe((res) => {
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item updated Successfully');
        this.formtitle = "New Unit";
      });
    }
  }



  deleteUnit(_id: string) {
    this.alertify.alert('Confirm Message', ()=> { this.con.delUnit(_id).subscribe((res) => {
      this.refreshData();
      this.alertify.success('Item Deleted Successfully');
    }); } );
  }


  onEdit(unit: U) {
    this.unit = unit;
  }

  export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}
  changetitle(){this.formtitle = "Edit Unit";}
}
