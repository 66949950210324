import { Component, OnInit, TemplateRef } from '@angular/core';

import { Supplier } from '../_models/supplier';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.css']
})
export class SuppliersComponent implements OnInit {

  title: string = "Suppliers"

  exportAsConfig: ExportAsConfig = {
      type: 'xlsx',
      elementIdOrContent: 'table',
      options: {  orientation: 'landscape',  margins: { top: '20' } },
      download: true,
      fileName: "exportedFile"
  }


  single: Supplier;
  modalRef: BsModalRef;


    supplier: Supplier;
    suppliers: Supplier[];


    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

    formtitle: string = "New Supplier"


    constructor(private con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }

    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }



    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getSupplier(id).subscribe((res: Supplier) => {
       this.single = res;

      })}




    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.supplier = { _id: "", name: "" ,createdAt: null, updatedAt: null};

    }

    refreshData() {
      this.con.getSuppliers().subscribe((res) => { this.suppliers = res as Supplier[]; });
      this.con.countSuppliers().subscribe((res) => { this.count = res; });

    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Supplier------------------------- */
          this.con.addSupplier(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Supplier------------------------- */
        this.con.putSupplier( SForm.value ).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Supplier"
        });
      }
    }



    deleteSupplier(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delSupplier(_id).subscribe((res) => {
        this.refreshData();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(supplier: Supplier) {
      this.supplier = supplier;
    }



    export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}

    changetitle(){this.formtitle = "Edit Supplier"}
  }

