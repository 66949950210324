import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map }  from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserX } from '../_models/userx';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedInStatus = false

  constructor(private http : HttpClient, private router: Router) { }


  userNow: string;
  userEmail: string;
  userRole: string;




    name: string;
    email: string;
    role: string;


  admin: boolean = false;
  getrole(){ if (this.role == "admin") this.admin = true; }




  loggedIn() { return !!localStorage.getItem('token'); }
  login(user){ return this.http.post(environment.baseUrl + 'auth/login', user); }
  logout() {  localStorage.removeItem('token');
              this.router.navigate(['/login']);
              return this.http.get(environment.baseUrl + 'auth/logout');
  }
  getToken() {  return localStorage.getItem('token') }

  getMe(){
    const token = localStorage.getItem("token");
    const header = new HttpHeaders().set('Authorization', `Bearer ${token}` );
    const options = {headers: header};
    return this.http.get(environment.baseUrl + 'auth/me', options)  };

    editMe(user){
      const token = localStorage.getItem("token");
      const header = new HttpHeaders().set('Authorization', `Bearer ${token}` );
      const options = {headers: header};
      return this.http.put(environment.baseUrl + 'auth/updatedetails', user, options);  };

      keyMe(user){
        const token = localStorage.getItem("token");
        const header = new HttpHeaders().set('Authorization', `Bearer ${token}` );
        const options = {headers: header};
        return this.http.put(environment.baseUrl + 'auth/updatepassword', user, options);  };
}
