import { Injectable } from '@angular/core';
import {  HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';


import { U, Unit } from '../_models/unit';
import { Product } from '../_models/product';
import { Join } from '../_models/join';

import { User } from '../_models/user';
import { Customer } from '../_models/customer';
import { Supplier } from '../_models/supplier';

import { Store } from '../_models/store';
import { Bank } from '../_models/bank';
import { Cash } from '../_models/cash';

import { OPS } from '../_models/ops'
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class ConService {


/* ------------------------units------------------------------ */
  // unit$: Unit;
  // units$: Observable<Unit[]>;


/* ------------------------products------------------------------ */
product: Product;
products: Product[];


/* ------------------------Joins------------------------------ */

join: Join;
joins: Join[];


/* ------------------------Users------------------------------ */
user: User;
users: User[];


/* ------------------------Customers------------------------------ */
customer: Customer;
customers: Customer[];


/* ------------------------suppliers------------------------------ */
supplier: Supplier;
suppliers: Supplier[];

/* ------------------------Stores------------------------------ */
store: Store;
stores: Store[];

/* ------------------------Banks------------------------------ */
bank: Bank;
banks: Bank[];

/* ------------------------CashBoxes------------------------------ */
cash: Cash;
cashes: Cash[];

/* ------------------------Operations------------------------------ */
ops: OPS;
OPSS: OPS[];

limit: number = 8;


token = localStorage.getItem("token");
header = new HttpHeaders().set('Authorization', `Bearer ${this.token}` );
options = {headers: this.header};



  constructor(private http: HttpClient) { }


  /* ------------------------units------------------------------ */
getUnits() {
  /* let params = new HttpParams().set("limit", "").set("page", ""); , {params}*/

  return this.http.get(environment.baseUrl+"unit")/* .pipe(map(data => data['data'])); */  }
/* countUnits() {  return this.http.get(environment.baseUrl+"unit").pipe(map(data => data['count']));  } */
getUnit(id) { return this.http.get(environment.baseUrl+"unit/"+id) }
addUnit( unit: U ) { return this.http.post(environment.baseUrl+"unit",  unit  ); }
putUnit( unit: U ) { return this.http.put(environment.baseUrl+"unit/" + unit._id, unit); }
delUnit( _id: string) { return this.http.delete(environment.baseUrl+"unit/" + _id); }
  /* ------------------------units------------------------------ */


  /* ------------------------products------------------------------ */
getProducts() {  return this.http.get(environment.baseUrl+"product").pipe(map(data => data['data']));  }
getProduct(id) {  return this.http.get(environment.baseUrl+"product/"+id).pipe(map(data => data['data']));  }
countProducts() {  return this.http.get(environment.baseUrl+"product").pipe(map(data => data['count']));  }
addProduct( product: Product ) { return this.http.post(environment.baseUrl+"product",  product  ); }
putProduct( product: Product ) { return this.http.put(environment.baseUrl+"product/" + product._id, product); }
delProduct( _id: string) { return this.http.delete(environment.baseUrl+"product/" + _id); }
  /* ------------------------products------------------------------ */




    /* ------------------------Joins------------------------------ */
getJoins() {  return this.http.get(environment.baseUrl+"supplierproduct").pipe(map(data => data['data']));  }
getJoin(id) {  return this.http.get(environment.baseUrl+"supplierproduct/"+id).pipe(map(data => data['data']));  }
countJoins() {  return this.http.get(environment.baseUrl+"supplierproduct").pipe(map(data => data['count']));  }
addJoin( join: Join ) { return this.http.post(environment.baseUrl+"supplierproduct",  join  ); }
putJoin( join: Join ) { return this.http.put(environment.baseUrl+"supplierproduct/" + join._id, join); }
delJoin( _id: string) { return this.http.delete(environment.baseUrl+"supplierproduct/" + _id); }
  /* ------------------------Joins------------------------------ */



      /* ------------------------Users------------------------------ */
getUsers() {  return this.http.get(environment.baseUrl+"user", this.options).pipe(map(data => data['data']));  }
getUser(id) {  return this.http.get(environment.baseUrl+"user/"+id, this.options).pipe(map(data => data['data']));  }
countUsers() {  return this.http.get(environment.baseUrl+"user", this.options).pipe(map(data => data['usersCount']));  }
countAdmins() {  return this.http.get(environment.baseUrl+"user", this.options).pipe(map(data => data['adminsCount']));  }
addUser( user: User ) { return this.http.post(environment.baseUrl+"user",  user , this.options ); }
putUser( user: User ) { return this.http.put(environment.baseUrl+"user/" + user._id, user, this.options); }
delUser( _id: string) { return this.http.delete(environment.baseUrl+"user/" + _id, this.options); }
  /* ------------------------Users------------------------------ */



  /* ------------------------Customers------------------------------ */
getCustomers() {  return this.http.get(environment.baseUrl+"customer").pipe(map(data => data['data']));  }
getCustomer(id) {  return this.http.get(environment.baseUrl+"customer/"+id).pipe(map(data => data['data']));  }
countCustomers() {  return this.http.get(environment.baseUrl+"customer").pipe(map(data => data['count']));  }
addCustomer( customer: Customer ) { return this.http.post(environment.baseUrl+"customer",  customer  ); }
putCustomer( customer: Customer ) { return this.http.put(environment.baseUrl+"customer/" + customer._id, customer); }
delCustomer( _id: string) { return this.http.delete(environment.baseUrl+"customer/" + _id); }
  /* ------------------------Customers------------------------------ */


    /* ------------------------suppliers------------------------------ */
    getSuppliers() {  return this.http.get(environment.baseUrl+"supplier").pipe(map(data => data['data']));  }
    getSupplier(id) {  return this.http.get(environment.baseUrl+"supplier/"+id).pipe(map(data => data['data']));  }
    countSuppliers() {  return this.http.get(environment.baseUrl+"supplier").pipe(map(data => data['count']));  }
    addSupplier( supplier: Supplier ) { return this.http.post(environment.baseUrl+"supplier",  supplier  ); }
    putSupplier( supplier: Supplier ) { return this.http.put(environment.baseUrl+"supplier/" + supplier._id, supplier); }
    delSupplier( _id: string) { return this.http.delete(environment.baseUrl+"supplier/" + _id); }
      /* ------------------------products------------------------------ */

      /* ------------------------Stores------------------------------ */
      getStores() {  return this.http.get(environment.baseUrl+"store").pipe(map(data => data['data']));  }
      getStore(id) {  return this.http.get(environment.baseUrl+"store/"+id).pipe(map(data => data['data']));  }
      countStores() {  return this.http.get(environment.baseUrl+"store").pipe(map(data => data['count']));  }
      addStore( store: Store ) { return this.http.post(environment.baseUrl+"store",  store  ); }
      putStore( store: Store ) { return this.http.put(environment.baseUrl+"store/" + store._id, store); }
      delStore( _id: string) { return this.http.delete(environment.baseUrl+"store/" + _id); }
        /* ------------------------Stores------------------------------ */



      /* ------------------------Banks------------------------------ */
      getBanks() {  return this.http.get(environment.baseUrl+"bank").pipe(map(data => data['data']));  }
      getBank(id) {  return this.http.get(environment.baseUrl+"bank/"+id).pipe(map(data => data['data']));  }
      countBanks() {  return this.http.get(environment.baseUrl+"bank").pipe(map(data => data['count']));  }
      addBank( bank: Bank ) { return this.http.post(environment.baseUrl+"bank",  bank  ); }
      putBank( bank: Bank ) { return this.http.put(environment.baseUrl+"bank/" + bank._id, bank); }
      delBank( _id: string) { return this.http.delete(environment.baseUrl+"bank/" + _id); }
        /* ------------------------Banks------------------------------ */


      /* ------------------------Cashes------------------------------ */
      getCashes() {  return this.http.get(environment.baseUrl+"cash").pipe(map(data => data['data']));  }
      getCashe(id) {  return this.http.get(environment.baseUrl+"cash/"+id).pipe(map(data => data['data']));  }
      countCashes() {  return this.http.get(environment.baseUrl+"cash").pipe(map(data => data['count']));  }
      addCash( cash: Cash ) { return this.http.post(environment.baseUrl+"cash",  cash  ); }
      putCash( cash: Cash ) { return this.http.put(environment.baseUrl+"cash/" + cash._id, cash); }
      delCash( _id: string) { return this.http.delete(environment.baseUrl+"cash/" + _id); }
        /* ------------------------Cashes------------------------------ */


/* ---------------------------------------------------------------------------------------
-----------------------------------   BUY    ---------------------------------------------
------------------------------------------------------------------------------------------ */




      /* ------------------------Buy Cashs------------------------------ */
      getBuyCashs() {  return this.http.get(environment.baseUrl+"opsbuycash").pipe(map(data => data['data']));  }
      getBuyCash(id) {  return this.http.get(environment.baseUrl+"opsbuycash/"+id).pipe(map(data => data['data']));  }
      countBuyCash() {  return this.http.get(environment.baseUrl+"opsbuycash").pipe(map(data => data['count']));  }
      addBuyCash( ops: OPS ) { return this.http.post(environment.baseUrl+"opsbuycash",  ops  ); }
      putBuyCash( ops: OPS ) { return this.http.put(environment.baseUrl+"opsbuycash/" + ops._id, ops); }
      delBuyCash( _id: string) { return this.http.delete(environment.baseUrl+"opsbuycash/" + _id); }
        /* ------------------------Buy Cashs------------------------------ */




      /* ------------------------Buy Bank------------------------------ */
      getBuyBanks() {  return this.http.get(environment.baseUrl+"opsbuybank").pipe(map(data => data['data']));  }
      getBuyBank(id) {  return this.http.get(environment.baseUrl+"opsbuybank/"+id).pipe(map(data => data['data']));  }
      countBuyBanks() {  return this.http.get(environment.baseUrl+"opsbuybank").pipe(map(data => data['count']));  }
      addBuyBank( ops: OPS ) { return this.http.post(environment.baseUrl+"opsbuybank",  ops  ); }
      putBuyBank( ops: OPS ) { return this.http.put(environment.baseUrl+"opsbuybank/" + ops._id, ops); }
      delBuyBank( _id: string) { return this.http.delete(environment.baseUrl+"opsbuybank/" + _id); }
        /* ------------------------Buy Bank------------------------------ */



              /* ------------------------Buy Credit------------------------------ */
      getBuyCredits() {  return this.http.get(environment.baseUrl+"opsbuycredit").pipe(map(data => data['data']));  }
      getBuyCredit(id) {  return this.http.get(environment.baseUrl+"opsbuycredit/"+id).pipe(map(data => data['data']));  }
      countBuyCredit() {  return this.http.get(environment.baseUrl+"opsbuycredit").pipe(map(data => data['count']));  }
      addBuyCredit( ops: OPS ) { return this.http.post(environment.baseUrl+"opsbuycredit",  ops  ); }
      putBuyCredit( ops: OPS ) { return this.http.put(environment.baseUrl+"opsbuycredit/" + ops._id, ops); }
      delBuyCredit( _id: string) { return this.http.delete(environment.baseUrl+"opsbuycredit/" + _id); }
        /* ------------------------Buy Credit------------------------------ */




/* ---------------------------------------------------------------------------------------
-----------------------------------   BUY    ---------------------------------------------
------------------------------------------------------------------------------------------ */







/* ---------------------------------------------------------------------------------------
-----------------------------------   Sell    ---------------------------------------------
------------------------------------------------------------------------------------------ */




      /* ------------------------Sell Cashs------------------------------ */
      getSellCashs() {  return this.http.get(environment.baseUrl+"opssellcash").pipe(map(data => data['data']));  }
      getSellCash(id) {  return this.http.get(environment.baseUrl+"opssellcash/"+id).pipe(map(data => data['data']));  }
      countSellCash() {  return this.http.get(environment.baseUrl+"opssellcash").pipe(map(data => data['count']));  }
      addSellCash( ops: OPS ) { return this.http.post(environment.baseUrl+"opssellcash",  ops  ); }
      putSellCash( ops: OPS ) { return this.http.put(environment.baseUrl+"opssellcash/" + ops._id, ops); }
      delSellCash( _id: string) { return this.http.delete(environment.baseUrl+"opssellcash/" + _id); }
        /* ------------------------Sell Cashs------------------------------ */




      /* ------------------------Sell Bank------------------------------ */
      getSellBanks() {  return this.http.get(environment.baseUrl+"opssellbank").pipe(map(data => data['data']));  }
      getSellBank(id) {  return this.http.get(environment.baseUrl+"opssellbank/"+id).pipe(map(data => data['data']));  }
      countSellBank() {  return this.http.get(environment.baseUrl+"opssellbank").pipe(map(data => data['count']));  }
      addSellBank( ops: OPS ) { return this.http.post(environment.baseUrl+"opssellbank",  ops  ); }
      putSellBank( ops: OPS ) { return this.http.put(environment.baseUrl+"opssellbank/" + ops._id, ops); }
      delSellBank( _id: string) { return this.http.delete(environment.baseUrl+"opssellbank/" + _id); }
        /* ------------------------Sell Bank------------------------------ */



              /* ------------------------Sell Credit------------------------------ */
      getSellCredits() {  return this.http.get(environment.baseUrl+"opssellcredit").pipe(map(data => data['data']));  }
      getSellCredit(id) {  return this.http.get(environment.baseUrl+"opssellcredit/"+id).pipe(map(data => data['data']));  }
      countSellCredit() {  return this.http.get(environment.baseUrl+"opssellcredit").pipe(map(data => data['count']));  }
      addSellCredit( ops: OPS ) { return this.http.post(environment.baseUrl+"opssellcredit",  ops  ); }
      putSellCredit( ops: OPS ) { return this.http.put(environment.baseUrl+"opssellcredit/" + ops._id, ops); }
      delSellCredit( _id: string) { return this.http.delete(environment.baseUrl+"opssellcredit/" + _id); }
        /* ------------------------Sell Credit------------------------------ */




/* ---------------------------------------------------------------------------------------
-----------------------------------   Sell    ---------------------------------------------
------------------------------------------------------------------------------------------ */



/* ---------------------------------------------------------------------------------------
-----------------------------------   opening Balances    -------------------------------
------------------------------------------------------------------------------------------ */


      /* ------------------------opening Cashs------------------------------ */
      getOpeningCashs() {  return this.http.get(environment.baseUrl+"opscash1st").pipe(map(data => data['data']));  }
      getOpeningCash(id) {  return this.http.get(environment.baseUrl+"opscash1st/"+id).pipe(map(data => data['data']));  }
      countOpeningCash() {  return this.http.get(environment.baseUrl+"opscash1st").pipe(map(data => data['count']));  }
      addOpeningCash( ops: OPS ) { return this.http.post(environment.baseUrl+"opscash1st",  ops  ); }
      putOpeningCash( ops: OPS ) { return this.http.put(environment.baseUrl+"opscash1st/" + ops._id, ops); }
      delOpeningCash( _id: string) { return this.http.delete(environment.baseUrl+"opscash1st/" + _id); }
        /* ------------------------Sell Cashs------------------------------ */




      /* ------------------------opening Bank------------------------------ */
      getOpeningBanks() {  return this.http.get(environment.baseUrl+"opsbank1st").pipe(map(data => data['data']));  }
      getOpeningBank(id) {  return this.http.get(environment.baseUrl+"opsbank1st/"+id).pipe(map(data => data['data']));  }
      countOpeningBank() {  return this.http.get(environment.baseUrl+"opsbank1st").pipe(map(data => data['count']));  }
      addOpeningBank( ops: OPS ) { return this.http.post(environment.baseUrl+"opsbank1st",  ops  ); }
      putOpeningBank( ops: OPS ) { return this.http.put(environment.baseUrl+"opsbank1st/" + ops._id, ops); }
      delOpeningBank( _id: string) { return this.http.delete(environment.baseUrl+"opsbank1st/" + _id); }
        /* ------------------------Sell Bank------------------------------ */



              /* ------------------------opening Customers------------------------------ */
      getOpeningCustomers() {  return this.http.get(environment.baseUrl+"opscustomer1st").pipe(map(data => data['data']));  }
      getOpeningCustomer(id) {  return this.http.get(environment.baseUrl+"opscustomer1st/"+id).pipe(map(data => data['data']));  }
      countOpeningCustomer() {  return this.http.get(environment.baseUrl+"opscustomer1st").pipe(map(data => data['count']));  }
      addOpeningCustomer( ops: OPS ) { return this.http.post(environment.baseUrl+"opscustomer1st",  ops  ); }
      putOpeningCustomer( ops: OPS ) { return this.http.put(environment.baseUrl+"opscustomer1st/" + ops._id, ops); }
      delOpeningCustomer( _id: string) { return this.http.delete(environment.baseUrl+"opscustomer1st/" + _id); }
        /* ------------------------Sell Credit------------------------------ */


                      /* ------------------------opening Suppliers ------------------------------ */
      getOpeningSuppliers() {  return this.http.get(environment.baseUrl+"opssupplier1st").pipe(map(data => data['data']));  }
      getOpeningSupplier(id) {  return this.http.get(environment.baseUrl+"opssupplier1st/"+id).pipe(map(data => data['data']));  }
      countOpeningSupplier() {  return this.http.get(environment.baseUrl+"opssupplier1st").pipe(map(data => data['count']));  }
      addOpeningSupplier( ops: OPS ) { return this.http.post(environment.baseUrl+"opssupplier1st",  ops  ); }
      putOpeningSupplier( ops: OPS ) { return this.http.put(environment.baseUrl+"opssupplier1st/" + ops._id, ops); }
      delOpeningSupplier( _id: string) { return this.http.delete(environment.baseUrl+"opssupplier1st/" + _id); }
        /* ------------------------Sell Credit------------------------------ */


                      /* ------------------------opening Stores------------------------------ */
      getOpeningStores() {  return this.http.get(environment.baseUrl+"opsstore1st").pipe(map(data => data['data']));  }
      getOpeningStore(id) {  return this.http.get(environment.baseUrl+"opsstore1st/"+id).pipe(map(data => data['data']));  }
      countOpeningStore() {  return this.http.get(environment.baseUrl+"opsstore1st").pipe(map(data => data['count']));  }
      addOpeningStore( ops: OPS ) { return this.http.post(environment.baseUrl+"opsstore1st",  ops  ); }
      putOpeningStore( ops: OPS ) { return this.http.put(environment.baseUrl+"opsstore1st/" + ops._id, ops); }
      delOpeningStore( _id: string) { return this.http.delete(environment.baseUrl+"opsstore1st/" + _id); }
        /* ------------------------Sell Credit------------------------------ */


/* ---------------------------------------------------------------------------------------
-----------------------------------  opening Balances    ---------------------------------
------------------------------------------------------------------------------------------ */






/* ---------------------------------------------------------------------------------------
      ----------------------------------   Payments    -------------------------------
------------------------------------------------------------------------------------------ */





              /* ------------------------Payments Customers------------------------------ */
      getCustomerPayments() {  return this.http.get(environment.baseUrl+"opspaymentcustomer").pipe(map(data => data['data']));  }

      getCustomerPaymentsCash() {  return this.http.get(environment.baseUrl+"opspaymentcustomer/cash").pipe(map(data => data['data']));  }
      getCustomerPaymentsBank() {  return this.http.get(environment.baseUrl+"opspaymentcustomer/bank").pipe(map(data => data['data']));  }

      getCustomerPayment(id) {  return this.http.get(environment.baseUrl+"opspaymentcustomer/"+id).pipe(map(data => data['data']));  }
      countCustomerPayment() {  return this.http.get(environment.baseUrl+"opspaymentcustomer").pipe(map(data => data['count']));  }

      countCustomerPaymentc() {  return this.http.get(environment.baseUrl+"opspaymentcustomer/cash").pipe(map(data => data['count']));  }
      countCustomerPaymentb() {  return this.http.get(environment.baseUrl+"opspaymentcustomer/bank").pipe(map(data => data['count']));  }

      addCustomerPayment( ops: OPS ) { return this.http.post(environment.baseUrl+"opspaymentcustomer",  ops  ); }
      putCustomerPayment( ops: OPS ) { return this.http.put(environment.baseUrl+"opspaymentcustomer/" + ops._id, ops); }
      delCustomerPayment( _id: string) { return this.http.delete(environment.baseUrl+"opspaymentcustomer/" + _id); }
        /* ------------------------Payments Customer------------------------------ */


        /* ------------------------Payments Suppliers ------------------------------ */
      getSupplierPayments() {  return this.http.get(environment.baseUrl+"opspaymentsupplier").pipe(map(data => data['data']));  }

      getSupplierPaymentsCash() {  return this.http.get(environment.baseUrl+"opspaymentsupplier/cash").pipe(map(data => data['data']));  }
      getSupplierPaymentsBank() {  return this.http.get(environment.baseUrl+"opspaymentsupplier/bank").pipe(map(data => data['data']));  }

      getSupplierPayment(id) {  return this.http.get(environment.baseUrl+"opspaymentsupplier/"+id).pipe(map(data => data['data']));  }
      countSupplierPayment() {  return this.http.get(environment.baseUrl+"opspaymentsupplier").pipe(map(data => data['count']));  }

      countSupplierPaymentc() {  return this.http.get(environment.baseUrl+"opspaymentsupplier/cash").pipe(map(data => data['count']));  }
      countSupplierPaymentb() {  return this.http.get(environment.baseUrl+"opspaymentsupplier/bank").pipe(map(data => data['count']));  }

      addSupplierPayment( ops: OPS ) { return this.http.post(environment.baseUrl+"opspaymentsupplier",  ops  ); }
      putSupplierPayment( ops: OPS ) { return this.http.put(environment.baseUrl+"opspaymentsupplier/" + ops._id, ops); }
      delSupplierPayment( _id: string) { return this.http.delete(environment.baseUrl+"opspaymentsupplier/" + _id); }
        /* ------------------------Payments Supplier------------------------------ */



/* ---------------------------------------------------------------------------------------
      -----------------------------------  Payments    ---------------------------------
------------------------------------------------------------------------------------------ */




      }
