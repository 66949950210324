import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { UnitsComponent } from './units/units.component';
import { ProductsComponent } from './products/products.component';
import { JoinComponent } from './join/join.component';
import { UsersComponent } from './users/users.component';
import { CustomersComponent } from './customers/customers.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { ProfileComponent } from './profile/profile.component';
import { StoresComponent } from './stores/stores.component';
import { CashBoxesComponent } from './cash-boxes/cash-boxes.component';
import { BanksComponent } from './banks/banks.component';


import { BCashComponent } from './buy/cash/cash.component';
import { BBankComponent } from './buy/bank/bank.component';
import { BCreditComponent } from './buy/credit/credit.component';

import { SCashComponent } from './sell/cash/cash.component';
import { SBankComponent } from './sell/bank/bank.component';
import { SCreditComponent } from './sell/credit/credit.component';
import { Cash1stBalanceComponent } from './1st_balance/cash1st-balance/cash1st-balance.component';
import { Bank1stBalanceComponent } from './1st_balance/bank1st-balance/bank1st-balance.component';
import { Customer1stBalanceComponent } from './1st_balance/customer1st-balance/customer1st-balance.component';
import { Supplier1stBalanceComponent } from './1st_balance/supplier1st-balance/supplier1st-balance.component';
import { Store1stBalanceComponent } from './1st_balance/store1st-balance/store1st-balance.component';
import { CustomersPaymentComponent } from './payment/customers-payment/customers-payment.component';
import { SuppliersPaymentComponent } from './payment/suppliers-payment/suppliers-payment.component';
import { Nav2Component } from './nav2/nav2.component';

// single route guard
const routes: Routes = [
  {path: 'n', component: Nav2Component},
  {path:'login', component: LoginComponent} ,
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]} ,
  {path: '', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},

  {path: 'units', component: UnitsComponent, canActivate: [AuthGuard]},
  {path: 'products', component: ProductsComponent, canActivate: [AuthGuard]},
  {path: 'join', component: JoinComponent, canActivate: [AuthGuard]},

  {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
  {path: 'customers', component: CustomersComponent, canActivate: [AuthGuard]},
  {path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuard]},

  {path: 'store', component: StoresComponent, canActivate: [AuthGuard]},
  {path: 'cash', component: CashBoxesComponent, canActivate: [AuthGuard]},
  {path: 'bank', component: BanksComponent, canActivate: [AuthGuard]},



  {path: 'buy-cash', component: BCashComponent, canActivate: [AuthGuard]},
  {path: 'buy-bank', component: BBankComponent, canActivate: [AuthGuard]},
  {path: 'buy-credit', component: BCreditComponent, canActivate: [AuthGuard]},

  {path: 'sell-cash', component: SCashComponent, canActivate: [AuthGuard]},
  {path: 'sell-bank', component: SBankComponent, canActivate: [AuthGuard]},
  {path: 'sell-credit', component: SCreditComponent, canActivate: [AuthGuard]},


  {path: 'cash-opening', component: Cash1stBalanceComponent, canActivate: [AuthGuard]},
  {path: 'bank-opening', component: Bank1stBalanceComponent, canActivate: [AuthGuard]},
  {path: 'customer-opening', component: Customer1stBalanceComponent, canActivate: [AuthGuard]},
  {path: 'supplier-opening', component: Supplier1stBalanceComponent, canActivate: [AuthGuard]},
  {path: 'store-opening', component: Store1stBalanceComponent, canActivate: [AuthGuard]},


  {path: 'customer-payment', component: CustomersPaymentComponent, canActivate: [AuthGuard]},
  {path: 'supplier-payment', component: SuppliersPaymentComponent, canActivate: [AuthGuard]},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
