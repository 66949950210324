import { Component, OnInit, TemplateRef } from '@angular/core';

import { Customer } from '../_models/customer';
import { Unit } from '../_models/unit';
import { Supplier } from '../_models/supplier';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';


@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {

  title: string = "Customers"

exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
}

  single: Customer;
  modalRef: BsModalRef;

  customer: Customer;
  customers: Customer[];

  units: Unit[];
  suppliers: Supplier[];

  count: any;
  term: any;
  p: number = 1;
  x: number = this.con.limit;

  formtitle: string = "New Customer"


  constructor(public con: ConService,
              private alertify: AlertifyService,
              private modalService: BsModalService,
              private exportAsService: ExportAsService) { }

  ngOnInit() {
    this.refreshData();
    this.resetForm();
  }



  openModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(template);
    this.con.getCustomer(id).subscribe((res: Customer) => {

     this.single = res;


    })}




  resetForm(myForm?: NgForm) {
    if (myForm) { myForm.reset(); }
    this.con.customer = { _id: "", name: "" ,createdAt: null, updatedAt: null};

  }

  refreshData() {
    this.con.getCustomers().subscribe((res) => { this.customers = res as Customer[]; });
    this.con.countCustomers().subscribe((res) => { this.count = res; });

  }


  onSubmit(CForm: NgForm) {
    if (CForm.value._id == '') {
  // /* --------------------add  Customer------------------------- */
  //       myform.value  = this.con.customer

        this.con.addCustomer(CForm.value).subscribe((res) => {

        this.resetForm();
        this.refreshData();
        this.alertify.success('Item Saved Successfully');


      });
    }
    else {
  /* --------------------update  Customer------------------------- */
      this.con.putCustomer( CForm.value ).subscribe((res) => {

        this.resetForm();
        this.refreshData();
        this.alertify.success('Item updated Successfully');
        this.formtitle = "New Customer"

      });
    }
  }



  deleteCustomer(_id: string) {
    this.alertify.alert('Confirm Message', ()=> { this.con.delCustomer(_id).subscribe((res) => {
      this.refreshData();
      this.alertify.success('Item Deleted Successfully');
    }); } );
  }


  onEdit(customer: Customer) {
    this.con.customer = customer;
  }


  export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}
  changetitle(){this.formtitle = "Edit Customer"}
}
