import { Component, OnInit, TemplateRef } from '@angular/core';

import { Customer } from '../../_models/customer';
import { Cash } from '../../_models/cash';
import { Bank } from '../../_models/bank';


import { OPS } from '../../_models/ops';

import { ConService } from '../../_services/con.service';
import { AlertifyService } from '../../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { map } from 'rxjs/operators';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-customers-payment',
  templateUrl: './customers-payment.component.html',
  styleUrls: ['./customers-payment.component.css']
})
export class CustomersPaymentComponent implements OnInit {

  title: string = "Buy Operation By Customer"
  exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
  }

  single: OPS;
  modalRef: BsModalRef;


    ops: OPS;
    opss: OPS[];

    customer: Customer;
    customers: Customer[];

    cash: Cash;
    cashs: Cash[];

    bank: Bank;
    banks: Bank[];



    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

     add: OPS;

     formtitle: string = "New Operation"

     isCash: boolean = true;
     hidden: boolean = false;
     isDisabled: boolean = false;
    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }
    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }




    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getCustomerPayment(id).subscribe((res: OPS) => {
       this.single = res;

      })}






    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.ops = { _id: "", customer: "",  cash: "",  bank: "",  method: "",  total: null };

    }

    refreshData() {
      this.con.getCustomers().subscribe((res) => { this.customers = res as Customer[]; });
      this.con.getCashes().subscribe((res) => { this.cashs = res as Cash[]; });
      this.con.getBanks().subscribe((res) => { this.banks = res as Bank[]; });
      this.con.getCustomerPayments().subscribe((res) => { this.opss = res as OPS[]; console.log(res); });
      this.con.countCustomerPayment().subscribe((res) => { this.count = res; });
    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {
        console.log(SForm.value);
     /* --------------------add  Customer------------------------- */


          this.con.addCustomerPayment(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Customer------------------------- */
console.log(SForm.value);
        this.con.putCustomerPayment( SForm.value ).subscribe((res) => {


          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Operation"
          this.hidden = false;
        });
      }
    }



    delete(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delCustomerPayment(_id).subscribe((res) => {
        this.refreshData();
        this.resetForm();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(ops: OPS) {
      this.con.ops = ops;


      this.hidden = true;

    }

    export(){this.exportAsService.save(this.exportAsConfig, 'exported-customers-payment').subscribe(() => {}); }

    changetitle(){this.formtitle = "Edit Operation"}


  }

