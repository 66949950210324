import { Component, OnInit, TemplateRef } from '@angular/core';

import { Cash } from '../_models/cash';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-cash-boxes',
  templateUrl: './cash-boxes.component.html',
  styleUrls: ['./cash-boxes.component.css']
})
export class CashBoxesComponent implements OnInit {

  title: string = "CashBoxes"

exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
}


  single: Cash;
  modalRef: BsModalRef;

  cash: Cash;
  cashes: Cash[];


    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

    formtitle: string = "New CashBox"


    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }

    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }



    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getCashe(id).subscribe((res: Cash) => {
       this.single = res;

      })}





    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.cash = { _id: "", name: "" ,createdAt: null, updatedAt: null};

    }

    refreshData() {
      this.con.getCashes().subscribe((res) => { this.cashes = res as Cash[]; });
      this.con.countCashes().subscribe((res) => { this.count = res; });

    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Cash------------------------- */
          this.con.addCash(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Cash------------------------- */
        this.con.putCash( SForm.value ).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New CashBox"
        });
      }
    }



    deleteCash(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delCash(_id).subscribe((res) => {
        this.refreshData();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(cash: Cash) {
      this.con.cash = cash;
    }


    export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}

    changetitle(){this.formtitle = "Edit CashBox"}
  }

