import { Component, OnInit } from '@angular/core';
import { Join } from '../_models/join';
import { Product } from '../_models/product';
import { Supplier } from '../_models/supplier';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';

import { ExportAsService, ExportAsConfig } from 'ngx-export-as';


@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css']
})
export class JoinComponent implements OnInit {

  title: string = "Prod-Supp Joins"

exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
}

  join: Join;
  joins: Join[];
  products: Product[];
  suppliers: Supplier[];



  count: any;
  Pcount: any;
  Scount: any;
  term: any;
  p: number = 1;
  x: number = this.con.limit;
  formtitle: string = "New Join"

  constructor( public con: ConService, private alertify: AlertifyService, private exportAsService: ExportAsService) { }

  ngOnInit() {
    this.refreshData();
    this.resetForm();
  }

  refreshData() {
    this.con.getJoins().subscribe((res) => { this.joins = res as Join[]; });
    this.con.countJoins().subscribe((res) => {  this.count = res; });

    this.con.getProducts().subscribe((res) => { this.products = res as Product[]; });
    this.con.countProducts().subscribe((res) => { this.Pcount = res; });

    this.con.getSuppliers().subscribe((res) => { this.suppliers = res as Supplier[];});
    this.con.countSuppliers().subscribe((res) => { this.Scount = res; });
  }



  resetForm(myForm?: NgForm) {
    if (myForm) { myForm.reset(); }
    this.con.join = { _id: "", supplier: "",  supplierName: "", product: "" , productName: "" ,createdAt: null, updatedAt: null};
  }

  onSubmit(myForm: NgForm) {
    if (myForm.value._id == "") {
  // /* --------------------add  Product------------------------- */
  //       myform.value  = this.con.product

        this.con.addJoin(myForm.value).subscribe((res) => {
        console.log( res );
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item Saved Successfully');

      });
    }
    else {
  /* --------------------update  Product------------------------- */
      this.con.putJoin( myForm.value ).subscribe((res) => {
        console.log(myForm.value);
        this.resetForm();
        this.refreshData();
        this.alertify.success('Item updated Successfully');
        this.formtitle = "New Join";
      });
    }
  }



  deleteJoin(_id: string) {
    this.alertify.alert('Confirm Message', ()=> { this.con.delJoin(_id).subscribe((res) => {
      this.refreshData();
      this.alertify.success('Item Deleted Successfully');
    }); } );
  }


  onEdit(join: Join) {
    this.con.join = join;
  }


  export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}

  changetitle(){this.formtitle = "Edit Join";}

}

