import { Component, OnInit, TemplateRef } from '@angular/core';

import { Bank } from '../_models/bank';

import { ConService } from '../_services/con.service';
import { AlertifyService } from '../_services/alertify.service';
import { NgForm } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.css']
})
export class BanksComponent implements OnInit {

  title: string = "Banks"

exportAsConfig: ExportAsConfig = {
    type: 'xlsx',
    elementIdOrContent: 'table',
    options: {  orientation: 'landscape',  margins: { top: '20' } },
    download: true,
    fileName: "exportedFile"
}


  single: Bank;
  modalRef: BsModalRef;


    bank: Bank;
    banks: Bank[];


    count: any;
    term: any;
    p: number = 1;
    x: number = this.con.limit;

    formtitle: string = "New Bank"

    constructor(public con: ConService,
                private alertify: AlertifyService,
                private modalService: BsModalService,
                private exportAsService: ExportAsService) { }
    ngOnInit() {
      this.refreshData();
      this.resetForm();
    }




    openModal(template: TemplateRef<any>, id) {
      this.modalRef = this.modalService.show(template);
      this.con.getBank(id).subscribe((res: Bank) => {
       this.single = res;

      })}






    resetForm(SForm?: NgForm) {
      if (SForm) { SForm.reset(); }
      this.con.bank = { _id: "", name: "" ,createdAt: null, updatedAt: null};

    }

    refreshData() {
      this.con.getBanks().subscribe((res) => { this.banks = res as Bank[]; });
      this.con.countBanks().subscribe((res) => { this.count = res; });

    }


    onSubmit(SForm: NgForm) {
      if ( SForm.value._id == "" ) {

     /* --------------------add  Bank------------------------- */
          this.con.addBank(SForm.value).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item Saved Successfully');

        });
      }
      else {

    /* --------------------update  Bank------------------------- */
        this.con.putBank( SForm.value ).subscribe((res) => {
          this.resetForm();
          this.refreshData();
          this.alertify.success('Item updated Successfully');
          this.formtitle = "New Bank"
        });
      }
    }



    deleteBank(_id: string) {
      this.alertify.alert('Confirm Message', ()=> { this.con.delBank(_id).subscribe((res) => {
        this.refreshData();
        this.alertify.success('Item Deleted Successfully');
      }); } );
    }


    onEdit(bank: Bank) {
      this.con.bank = bank;
    }
    export(){this.exportAsService.save(this.exportAsConfig, `${this.title}`).subscribe(() => {});}

    changetitle(){this.formtitle = "Edit Bank"}

  }

